import { Component } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ApiLiv } from 'src/app/core/services/api/api-liv.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EAccessProfile } from 'src/app/shared/enums/access-profile.enum';

import { Menu, menu } from './menu';
import { SidebarMenuStore } from '../store/sidebar-menu.store';

@Component({
  selector: 'liv-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  protected menu: Menu[];
  protected loadingMaterials = false;
  protected isAdminUser = false;

  constructor(
    private apiLiv: ApiLiv,
    private permissionService: PermissionService,
    private sidebarMenuStore: SidebarMenuStore
  ) {
    this.isAdminUser = this.permissionService.hasPermission([
      EAccessProfile.it,
      EAccessProfile.administrator,
      EAccessProfile.adviser,
      EAccessProfile.schoolMaster
    ]);
    this.buildDynamicMenu();
  }

  get isCollapsed() {
    return this.sidebarMenuStore.isCollapsed;
  }

  buildDynamicMenu(): void {
    if (this.permissionService.isSpaceToBe) {
      const SPACE_TO_BE_ROUTES = ['Materiais'];
      this.menu = menu.filter((item) => SPACE_TO_BE_ROUTES.includes(item.name));
    } else {
      this.menu = menu;
    }
  }

  toggleCollapse() {
    this.sidebarMenuStore.toggleCollapse();
  }

  onRequestMaterials(): void {
    if (!this.loadingMaterials) {
      this.loadingMaterials = true;
      this.apiLiv
        .get<string>('/url/PortalOperacoes')
        .pipe(
          take(1),
          finalize(() => (this.loadingMaterials = false))
        )
        .subscribe((data) => window.open(`${data}`));
    }
  }
}
