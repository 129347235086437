<div class="notification-list-container">
  <ul class="notification-list">
    <li *ngFor="let notification of notifications">
      <liv-notification-item
        [notification]="notification"
        [preview]="true"
        [unread]="!notification.read"
        (seeNotification)="handleSeeNotification($event)"
        (allNotificationsSeen)="emitAllNotificationsSeen()"
      ></liv-notification-item>
    </li>
  </ul>
</div>
<div class="footer-container">
  <footer class="notification-footer">
    <button
      class="notification-footer__buttonSeeAll"
      (click)="handleSeeAllNotifications()"
      [disabled]="loadingAllNotifications"
    >
      Ver todos
      <commons-loader
        *ngIf="loadingAllNotifications"
        size="sm"
      ></commons-loader>
    </button>
  </footer>
</div>
