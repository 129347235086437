import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModel } from '../../models/notification.model';

@Component({
  selector: 'liv-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsModalComponent {
  @Input() notifications: NotificationModel[];

  constructor(private _activeModal: NgbActiveModal) {}

  onClose(): void {
    this._activeModal.close(true);
  }
}
