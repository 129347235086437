@if (breadcrumbs?.length) {
  <nav aria-label="Breadcrumb">
    <ol role="list" class="hidden md:flex items-center space-x-1.5">
      @for (breadcrumb of breadcrumbs; track $index; let last = $last) {
        <li class="flex items-center !gap-1.5">
          @if (!last) {
            <a
              class="block text-neutral-low-light leading-normal text-sm hover:text-neutral-low-medium truncate transition-colors duration-150"
              [routerLink]="[breadcrumb.path]"
              [queryParams]="breadcrumb.params"
            >
              <span>
                {{ breadcrumb.label }}
              </span>
            </a>

            <!-- Ícone de seta somente se não for o último breadcrumb -->
            <ng-icon
              name="featherChevronRight"
              size="20"
              class="flex-shrink-0 text-neutral-low-light"
            ></ng-icon>
          } @else {
            <span class="text-brand-primary-pure font-medium text-sm/normal">{{
              breadcrumb.label
            }}</span>
          }
        </li>
      }
    </ol>

    <div class="md:hidden">
      @if (breadcrumbs.length > 1) {
        <a
          class="flex items-center !gap-1 text-brand-primary-pure hover:text-brand-primary-dark transition-colors duration-150"
          [routerLink]="[breadcrumbs[breadcrumbs.length - 2].path]"
          [queryParams]="breadcrumbs[breadcrumbs.length - 2].params"
        >
          <ng-icon
            name="featherChevronLeft"
            size="16"
            class="flex-shrink-0 text-current"
          ></ng-icon>

          <span class="truncate inline-block w-full text-sm/normal">
            {{ breadcrumbs[breadcrumbs.length - 2].label }}
          </span>
        </a>
      } @else {
        <span class="text-brand-primary-pure font-medium text-sm/normal">{{
          breadcrumbs[0].label
        }}</span>
      }
    </div>
  </nav>
}
