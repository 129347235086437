import { Component, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'liv-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent {
  @Input() breadcrumbs: Breadcrumb[];
}
