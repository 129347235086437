<div class="notification-block">
  <div id="notification-block__buttonWrapper">
    <span
      class="notification-badge"
      [ngClass]="{ 'notification-badge--active': hasNewNotification }"
    ></span>
    <commons-icon-button
      svgPath="assets/icons/bell.svg"
      [small]="true"
      [disabled]="(lastNotifications$ | async) === null"
      (buttonClick)="handleToggleNotifications()"
    ></commons-icon-button>
  </div>
  <div
    *ngIf="lastNotifications$ | async as notifications"
    class="dropdown"
    [ngClass]="{ 'dropdown--active': expandNotificationDropdown }"
  >
    <div class="notification-container">
      <header class="notification-header">
        <h4 class="notification-header__title">Notificações</h4>
      </header>
      <main class="notification-main">
        <ng-container *ngIf="selectedNotification; else previewNotifications">
          <liv-notification-item
            [notification]="selectedNotification"
            (seeNotification)="toggleSeeNotification($event)"
          ></liv-notification-item>
        </ng-container>
        <ng-template #previewNotifications>
          <ng-container
            *ngIf="notifications?.length > 0; else withoutNotification"
          >
            <liv-notifications-list
              [notifications]="notifications"
              (seeNotification)="toggleSeeNotification($event)"
              (allNotificationsSeen)="handleAllNotificationSeen()"
            ></liv-notifications-list>
          </ng-container>
          <ng-template #withoutNotification>
            <div class="without-notification-content">
              <h4 class="without-notification-content__title">Ops! 🙁</h4>
              <p class="without-notification-content__description">
                Não há notificações para este ano no momento.
              </p>
            </div>
          </ng-template>
        </ng-template>
      </main>
    </div>
  </div>
</div>

<div
  class="overlay-panel"
  overlay
  (onClickOuside)="onClickOuside()"
  [activeOverlay]="expandNotificationDropdown"
></div>
