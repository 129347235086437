<article
  class="notification-item"
  [ngClass]="{
    'notification-item--preview': preview,
    'notification-item--unread': unread,
    'notification-item--read': !unread
  }"
>
  <header class="notification-item__header">
    <span
      class="notification-badge"
      [ngClass]="'notification-badge--active'"
    ></span>
    <span class="notification-item__header__title">
      {{ notification?.title }}
    </span>
    <ng-container *ngIf="isNewNotification; else oldNotification">
      <span class="notification-item__header__new">Novo</span>
    </ng-container>
    <ng-template #oldNotification>
      <time class="notification-item__header__time">{{
        notification?.last_send || notification?.created_at
          | date : 'mediumDate'
      }}</time>
    </ng-template>
  </header>
  <main>
    <div
      class="notification-item__content"
      [innerHTML]="notification?.description | safeTransform : 'HTML'"
    ></div>
    <ng-container *ngIf="preview; else currentNotification">
      <button
        class="notification-item__button"
        (click)="handleSeeNotification(notification)"
      >
        Ver mais
      </button>
    </ng-container>
    <ng-template #currentNotification>
      <button
        class="notification-item__button"
        (click)="handleSeeNotification(null)"
      >
        Voltar
      </button>
    </ng-template>
  </main>
</article>
