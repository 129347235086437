import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionService } from '../../services/connection.service';

@Injectable({ providedIn: 'root' })
export class OfflineGuard {
  constructor(
    private router: Router,
    private connectionService: ConnectionService
  ) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.connectionService.connected$.pipe(
      map((isConnected) => {
        if (!isConnected) {
          return this.router.parseUrl('/offline');
        }
        return true;
      })
    );
  }
}
