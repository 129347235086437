<div data-cid="shell-container">
  <liv-sidebar-menu></liv-sidebar-menu>
  <div
    class="relative grid min-h-screen w-full grid-cols-[100%] grid-rows-[auto,1fr,auto] lg:pl-64"
  >
    <liv-header></liv-header>
    <main class="!px-4 !pt-4 lg:!px-6">
      <router-outlet></router-outlet>
    </main>
    <liv-footer></liv-footer>
  </div>
</div>
