import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NotificationModel } from '../../models/notification.model';
import { DayjsService } from 'src/app/core/services/dayjs.service';
import { KibanaService } from 'src/app/core/services/api/requests/kibana.service';
import { take } from 'rxjs';

@Component({
  selector: 'liv-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationItemComponent {
  @Input() notification: NotificationModel;
  @Input() preview = false;
  @Input() unread: Boolean;
  @Output() seeNotification = new EventEmitter<NotificationModel | null>(null);
  @Output() allNotificationsSeen = new EventEmitter<void>();

  constructor(
    private dayJsService: DayjsService,
    private kibanaService: KibanaService
  ) {}

  get isNewNotification(): boolean {
    return (
      this.dayJsService.diff(
        new Date(),
        new Date(this.notification.updated_at),
        'hour'
      ) < 24
    );
  }

  handleSeeNotification(notification: NotificationModel | null): void {
    this.seeNotification.emit(notification);

    if (notification) {
      this.kibanaService
        .saveNotificationDetailsClick({
          title: notification.title
        })
        .pipe(take(1))
        .subscribe();
    }
  }
}
