import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShellComponent } from './shell.component';
import { AuthGuard } from '../core/security/guards/auth.guard';
import { OfflineGuard } from '../core/security/guards/offline.guard';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard, OfflineGuard],
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'inicio'
          },
          {
            path: 'inicio',
            loadChildren: () =>
              import('../modules/home/home.module').then((m) => m.HomeModule)
          },
          {
            path: 'percurso',
            loadChildren: () =>
              import(
                '../modules/pedagogical-path/pedagogical-path.module'
              ).then((m) => m.PedagogicalPathModule)
          },
          {
            path: 'formacoes',
            loadChildren: () =>
              import('../modules/formations/formations.module').then(
                (m) => m.FormationsModule
              )
          },
          {
            path: 'live-stream',
            loadChildren: () =>
              import('../modules/live-stream/live-stream.module').then(
                (m) => m.LiveStreamModule
              )
          },
          // {
          //   path: 'treinamentos',
          //   loadChildren: () =>
          //     import('../modules/trainings/trainings.module').then(
          //       (m) => m.TrainingsModule
          //     )
          // },
          {
            path: 'configuracoes-usuario',
            loadChildren: () =>
              import('../modules/user-settings/user-settings.module').then(
                (m) => m.UserSettingsModule
              )
          },
          {
            path: 'biblioteca',
            loadChildren: () =>
              import('../modules/library/library.module').then(
                (m) => m.LibraryModule
              )
          }
        ]
      },
      {
        path: 'materiais',
        loadChildren: () =>
          import('../modules/materials/materials.module').then(
            (m) => m.MaterialsModule
          )
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('../modules/portfolio/portfolio.module').then(
            (m) => m.PortfolioModule
          )
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShellRoutingModule {}
