import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgIconsModule } from '@ng-icons/core';
import {
  featherChevronLeft,
  featherChevronRight,
  featherHome
} from '@ng-icons/feather-icons';
import { heroBars3, heroXMark } from '@ng-icons/heroicons/outline';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ShellRoutingModule } from './shell-routing.module';
import { ShellComponent } from './shell.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { CommonsModule } from '../core/commons';
import { NotificationsModule } from '../modules/notifications/notifications.module';
import { UserSettingsModule } from '../modules/user-settings/user-settings.module';
import { UiModule } from '../ui/ui.module';

@NgModule({
  declarations: [
    SidebarMenuComponent,
    BreadcrumbComponent,
    ShellComponent,
    HeaderComponent,
    FooterComponent,
    SubheaderComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ShellRoutingModule,
    CommonsModule,
    CommonModule,
    NotificationsModule,
    UserSettingsModule,
    FormsModule,
    UiModule,
    AngularSvgIconModule,
    NgIconsModule.withIcons({
      featherChevronRight,
      featherChevronLeft,
      featherHome,
      heroBars3,
      heroXMark
    })
  ],
  exports: [ShellComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShellModule {}
