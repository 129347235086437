<footer class="flex h-14 justify-between !px-4 !pb-2 !pt-5 xl:!px-6">
  <div>
    <div class="flex items-baseline !gap-2">
      <a
        class="font-roboto text-xs font-medium text-brand-primary-pure no-underline after:relative after:z-[-1] after:block after:origin-top-right after:scale-x-0 after:transform after:rounded-sm after:border-b-2 after:border-brand-primary-pure after:opacity-0 after:transition-transform after:delay-75 after:duration-300 after:ease-out hover:after:origin-top-left hover:after:scale-x-100 hover:after:transform hover:after:opacity-100 hover:after:delay-75 hover:after:duration-300 hover:after:ease-out"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.inteligenciadevida.com.br/"
        >Laboratório Inteligência de Vida</a
      >
      <span class="self-center font-medium text-brand-primary-pure">|</span>
      <small class="text-xs/6 font-medium text-brand-primary-pure">
        Versão: <span>{{ environment.version }}</span>
      </small>
    </div>
  </div>

  <div class="text-neutral-low-dark">
    <small class="leading-6"> © Copyright {{ currentYear }} </small>
  </div>
</footer>
