import { Component, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'liv-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[];
}
