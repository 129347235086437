import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarMenuStore {
  private _isCollapsed = signal(true);
  isCollapsed = this._isCollapsed.asReadonly();

  toggleCollapse() {
    this._isCollapsed.update((prev) => !prev);
  }
}
