import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ESession } from 'src/app/shared/enums/storage.enum';
import { AuthStore } from '../../../shared/store/auth.store';
import { ApiLiv } from '../../services/api/api-liv.service';
import { SessionService } from '../../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authStore: AuthStore,
    private apiLiv: ApiLiv,
    private sessionService: SessionService
  ) {}

  canLoad(
    _: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const url = segments?.map((s) => `/${s?.toString()}`).join('');
    return this.isAuthorized(url);
  }

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.isAuthorized(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }

  private isAuthorized(redirect: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const { token } = this.authStore.authSnapshot || {};
      const isAuthorized = !!token;

      if (isAuthorized) {
        const autoLogin = this.sessionService.get<string>(ESession.auto_login);

        if (!autoLogin) {
          this.sessionService.save(
            ESession.auto_login,
            String(new Date().getTime())
          );
          this.apiLiv
            .post<void>('/Kibana/Login/SaveAutoLogin', null)
            .pipe(
              take(1),
              catchError(() => {
                this.sessionService.delete(ESession.auto_login);
                return EMPTY;
              })
            )
            .subscribe();
        }
        return resolve(true);
      }

      this.router.navigate(['auth', 'login'], {
        queryParams: { redirect }
      });

      return reject(false);
    });
  }
}
