import { Injectable } from '@angular/core';
import {
  LogLevel,
  HubConnectionBuilder,
  HubConnection
} from '@microsoft/signalr';
//import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { NotificationModel } from 'src/app/modules/notifications/models/notification.model';
import { environment as ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private _lastNotification = new ReplaySubject<NotificationModel>(1);
  lastNotification$ = this._lastNotification.asObservable();

  private _hubConnection: HubConnection;
  private _reConnect: NodeJS.Timeout;
  private readonly _serverTimeoutInMilliseconds = 100000;

  constructor() {}

  startConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl(`${ENV.apiUrl}/notify`)
      .build();

    this._hubConnection.serverTimeoutInMilliseconds =
      this._serverTimeoutInMilliseconds;

    /*     this._hubConnection
      .start()
      .then(() => {
        console.log('WebSocket: Connection Started');
        this.dataListenerDisplayMessage();
        if (this._reConnect) {
          clearInterval(this._reConnect);
        }
      })
      .catch(err => {
        if (!this._reConnect) {
          this.reConectSignalR();
        }
        throw new FailedToNegotiateWithServerError(
          'WebSocket: Error while starting connection =  ' + JSON.stringify(err)
        );
      }); */

    this._hubConnection.onclose(() => {
      console.info('Connection WebSocket onclose');
      this.reConectSignalR();
    });
  }

  private dataListenerDisplayMessage = () => {
    this._hubConnection.on('DisplayMessage', (data: NotificationModel) => {
      console.log('WebSocket: DisplayMessage', data);
      this._lastNotification.next(data);
    });
  };

  private reConectSignalR() {
    this._reConnect = setInterval(() => {
      this.startConnection();
    }, this._serverTimeoutInMilliseconds);
  }
}
