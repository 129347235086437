import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, filter, map } from 'rxjs';
import {
  Breadcrumb,
  BreadcrumbService
} from 'src/app/core/services/breadcrumb.service';
import { AuthStore } from 'src/app/shared/store/auth.store';
import { CapitalizeUtils } from 'src/app/shared/utils/capitalize.utils';

import { SidebarMenuStore } from '../store/sidebar-menu.store';

@Component({
  selector: 'liv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly breadcrumbs$: Observable<Breadcrumb[]>;

  protected enableBreadcrumb = false;
  protected greetings = '';

  private subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authStore: AuthStore,
    private breadcrumbService: BreadcrumbService,
    private sidebarMenuStore: SidebarMenuStore
  ) {
    this.breadcrumbs$ = this.breadcrumbService.getBreadcrumbs$();
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          })
        )
        .subscribe((route) => {
          this.enableBreadcrumb = !!route.snapshot.data['breadcrumb'];
        })
    );
  }

  ngOnInit(): void {
    this.greetings = this.getGreetings();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getGreetings(): string {
    const { nome } = this.authStore.authSnapshot || {};

    if (!nome) {
      return 'Bem-vindo(a) de volta';
    }

    const [username] = nome.split(' ');

    return `Professor(a) ${CapitalizeUtils.capitalize(username)}`;
  }

  toggleCollapse() {
    this.sidebarMenuStore.toggleCollapse();
  }
}
