import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  interval,
  throwError,
  zip
} from 'rxjs';
import { catchError, startWith, switchMap, take, tap } from 'rxjs/operators';
import { GradeModel } from 'src/app/core/models/grade.model';
import { KibanaService } from 'src/app/core/services/api/requests/kibana.service';
import { NotificationService } from 'src/app/modules/notifications/services/notification.service';
import { AuthStore } from 'src/app/shared/store/auth.store';

import { NotificationModel } from './models/notification.model';

@Component({
  selector: 'liv-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit, OnDestroy {
  selectedNotification: NotificationModel;
  selectedGrade: GradeModel;
  expandNotificationDropdown = false;
  showNotificationDropdown = false;
  hasNewNotification = false;
  overlay = new BehaviorSubject<boolean>(false);
  overlay$ = this.overlay.asObservable();
  private _currentNotifications = new BehaviorSubject<NotificationModel[]>([]);
  lastNotifications$ = this._currentNotifications.asObservable();
  private userId: number;

  private destroy$ = new Subject<boolean>();

  constructor(
    private notificationService: NotificationService,
    private kibanaService: KibanaService,
    private authStore: AuthStore
  ) {}

  ngOnInit(): void {
    const hourUnit = 3600000;
    this.userId = this.authStore.authSnapshot.id;

    interval(hourUnit)
      .pipe(
        startWith(0),
        switchMap(() => this.getNotifications()),
        tap((notifications) => this.setNotifications(notifications))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getNotifications(): Observable<NotificationModel[]> {
    return this.notificationService
      .getRecentNotifications()
      .pipe(catchError((err) => throwError(() => err)));
  }

  handleToggleNotifications(): void {
    this.expandNotificationDropdown = !this.expandNotificationDropdown;
    if (this.expandNotificationDropdown) {
      this.kibanaService.saveNotificationListClick().pipe(take(1)).subscribe();
    }
  }

  toggleSeeNotification($event: NotificationModel | null): void {
    this.selectedNotification = $event;
    if (this.selectedNotification) {
      if (!this.selectedNotification.read) {
        this.notificationService
          .postNotificationRead(this.selectedNotification.id)
          .pipe(take(1))
          .subscribe();
      }
      this.selectedNotification.read = true;
    }

    if (this._currentNotifications.getValue().some((n) => !n.read)) {
      this.hasNewNotification = true;
    } else {
      this.hasNewNotification = false;
    }
  }

  handleAllNotificationSeen(): void {
    const notifications = this._currentNotifications.getValue();
    zip(
      notifications.map((n) =>
        this.notificationService.postNotificationRead(n.id)
      )
    ).subscribe(() => {
      notifications.forEach((n) => (n.read = true));
      this.hasNewNotification = false;
    });
  }

  onClickOuside(): void {
    if (this.expandNotificationDropdown) {
      this.expandNotificationDropdown = false;
      this.selectedNotification = null;
    }
  }

  setNotifications(notifications: NotificationModel[]): void {
    const currentNotifications = this._currentNotifications.getValue();
    notifications = notifications ?? [];

    if (
      currentNotifications.some((n) => !n.read) ||
      notifications.some((n) => !n.read)
    ) {
      this.hasNewNotification = true;
    }

    const diff = currentNotifications.filter(
      (notification) => !notifications.some((n) => n.id === notification.id)
    );

    if (diff) {
      this._currentNotifications.next(notifications);
    }
  }
}
