<header class="modal-notification-header" #modalHeader>
  <h2 class="modal-notification-header__title">Notificações</h2>
  <button
    type="button"
    class="modal-notification-header__closeButton"
    (click)="onClose()"
  >
    <svg-icon
      src="assets/icons/close.svg"
      [svgStyle]="{
        'width.px': 16,
        'height.px': 16
      }"
    ></svg-icon>
  </button>
</header>
<div class="modal-notification-body">
  <ng-container *ngIf="notifications?.length > 0">
    <article
      class="notification-item"
      *ngFor="let notification of notifications"
    >
      <header class="notification-item__header">
        <strong class="notification-item__header__title">
          {{ notification.title }}
        </strong>
        <time class="notification-item__header__time">{{
          notification?.last_send || notification?.updated_at | date: 'mediumDate'
        }}</time>
      </header>
      <div
        class="notification-item__content"
        [innerHTML]="notification.description"
      ></div>
    </article>
  </ng-container>
</div>
