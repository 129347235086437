import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { CommonsModule } from 'src/app/core/commons';
import { NotificationsComponent } from './notifications.component';
import { NotificationsModalComponent } from './components/notifications-modal/notifications-modal.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { SignalRService } from 'src/app/core/services/signalR.service';

@NgModule({
  declarations: [
    NotificationItemComponent,
    NotificationsListComponent,
    NotificationsComponent,
    NotificationsModalComponent
  ],
  imports: [CommonModule, CommonsModule, SharedModule, AngularSvgIconModule],
  providers: [SignalRService],
  exports: [
    NotificationsComponent,
    NotificationItemComponent,
    NotificationsListComponent,
    NotificationsComponent,
    NotificationsModalComponent
  ]
})
export class NotificationsModule {}
