import { Component } from '@angular/core';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'liv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  protected readonly environment = ENV;
  protected readonly currentYear = new Date().getFullYear();

  get environmentName(): string {
    const environtmentStage = {
      ['Homologation']: 'Homologação',
      ['Development']: 'Desenvolvimento',
      ['Production']: 'Produção'
    };
    return (environtmentStage[this.environment.stage] as string) ?? '-';
  }
}
