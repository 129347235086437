import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
  private _connected: BehaviorSubject<boolean> = new BehaviorSubject(
    window.navigator.onLine
  );
  connected$: Observable<boolean> = this._connected.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.onOnline();
    this.onOffline();
  }

  get isConnected(): boolean {
    return this._connected.value;
  }

  private onOnline(): void {
    fromEvent(window, 'online')
      .pipe(tap(() => this.toastService.success('Conexão estabelecida!')))
      .subscribe(() => {
        this._connected.next(true);

        const redirect = this.route.snapshot.queryParamMap.get('redirect');
        const decodedUrl = redirect ? decodeURIComponent(redirect) : '/inicio';

        this.router.navigateByUrl(decodedUrl);
      });
  }

  private onOffline(): void {
    fromEvent(window, 'offline')
      .pipe(tap(() => this.toastService.info('Sem conexão com a internet!')))
      .subscribe(() => {
        this._connected.next(false);

        this.router.navigate(['offline'], {
          queryParams: { redirect: this.router.url }
        });
      });
  }
}
