<header
  class="flex items-center justify-between !gap-2 border-b border-brand-primary-lighter bg-white !px-4 !py-3 xl:!px-6"
>
  <div class="inline-flex flex-1 items-center !gap-2">
    <button
      class="inline-flex text-brand-primary-pure transition-colors duration-150 hover:text-brand-primary-dark lg:hidden"
      aria-label="Toggle sidebar"
      aria-describedby="sidebar"
      tabindex="0"
      (click)="toggleCollapse()"
    >
      <ng-icon name="heroBars3" size="32" />
    </button>

    @if (enableBreadcrumb) {
      <liv-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></liv-breadcrumb>
    } @else {
      <h4
        class="line-clamp-2 font-roboto text-sm text-neutral-low-pure md:text-base xl:text-lg"
      >
        Bem-vindo(a) de volta,
        <br class="sm:hidden" />
        <span class="font-medium">
          {{ greetings }}
        </span>
      </h4>
    }
  </div>

  <div class="flex items-center !gap-4 md:!gap-6">
    <div class="flex items-center !gap-2 md:!gap-3">
      <liv-notifications></liv-notifications>
    </div>
    <liv-user-settings></liv-user-settings>
  </div>
</header>
