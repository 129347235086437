import { Injectable } from '@angular/core';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiLiv } from '../../../core/services/api/api-liv.service';
import { NotificationModel } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _path = '/Notification';

  constructor(private apiLiv: ApiLiv) {}

  getAllNotifications(): Observable<NotificationModel[]> {
    return this.apiLiv.get<NotificationModel[]>(`${this._path}`);
  }

  getRecentNotifications(): Observable<NotificationModel[]> {
    return this.apiLiv.get<NotificationModel[]>(`${this._path}?limit=15`).pipe(
      catchError((err) => {
        throw new FailedToNegotiateWithServerError(
          'WebSocket: connection =  ' + JSON.stringify(err)
        );
      })
    );
  }

  postNotificationRead(
    idNotification: number
  ): Observable<NotificationModel[]> {
    return this.apiLiv
      .post<NotificationModel[]>(`${this._path}/read/${idNotification}`, {})
      .pipe(
        catchError((err) => {
          throw new FailedToNegotiateWithServerError(
            'WebSocket: connection =  ' + JSON.stringify(err)
          );
        })
      );
  }
}
