import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/modules/notifications/services/notification.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { NotificationsModalComponent } from 'src/app/modules/notifications/components/notifications-modal/notifications-modal.component';
import { GradeModel } from 'src/app/core/models/grade.model';
import { NotificationModel } from '../../models/notification.model';
import { EMPTY, catchError, finalize } from 'rxjs';
import { AuthStore } from 'src/app/shared/store/auth.store';

@Component({
  selector: 'liv-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {
  @Input() notifications: NotificationModel[];
  @Output() seeNotification = new EventEmitter<NotificationModel | null>(null);
  @Output() allNotificationsSeen = new EventEmitter<void>();
  loadingAllNotifications = false;
  selectedGrade: GradeModel;

  private readonly modalRefSettings = {
    centered: true,
    scrollable: true,
    modalDialogClass: 'notifications-modal'
  };

  constructor(
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private ngbModal: NgbModal,
    private toastService: ToastService,
    private authStore: AuthStore
  ) {}

  handleSeeAllNotifications(): void {
    if (!this.loadingAllNotifications) {
      this.loadingAllNotifications = true;

      this.seeCurrentNotifications();
      this.getAllNotifications();
    }
  }

  getAllNotifications(): void {
    this.notificationService
      .getAllNotifications()
      .pipe(
        catchError(() => {
          this.showToastLoadingAllNotificationsError();
          return EMPTY;
        }),
        finalize(() => {
          this.loadingAllNotifications = false;
        })
      )
      .subscribe((notifications) => {
        const modalRef: NgbModalRef = this.ngbModal.open(
          NotificationsModalComponent,
          this.modalRefSettings
        );
        if (notifications) {
          (
            modalRef.componentInstance as {
              notifications;
            }
          ).notifications = notifications.map((notification) => {
            return {
              ...notification,
              safeDescricao: this.sanitizer.bypassSecurityTrustHtml(
                `${notification.description}`
              )
            };
          });
        } else {
          this.showToastLoadingAllNotificationsError();
          modalRef.close();
        }
      });
  }

  private showToastLoadingAllNotificationsError(): void {
    this.toastService.error('Houve um erro ao carregar todas a notificações');
  }

  private seeCurrentNotifications(): void {
    this.seeNotification.emit(null);
    this.allNotificationsSeen.emit();
  }

  handleSeeNotification(notification: NotificationModel | null): void {
    this.seeNotification.emit(notification);
  }

  emitAllNotificationsSeen(): void {
    if (
      !this.notifications.filter((notification) => !notification.read).length
    ) {
      this.allNotificationsSeen.emit();
    }
  }
}
